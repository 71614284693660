import React, { useState, useEffect, useContext } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { motion } from "framer-motion";
import TextField from "@mui/material/TextField";
import { Swiper, SwiperSlide } from "swiper/react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { MdOutlineClose, MdShoppingBasket } from "react-icons/md";
import ModalPhoto from "../components/product/ModalPhoto";
import MetaTag from "../components/metaTag";
import { textFieldSx } from "../components/product/mui.style";
import {
  previewContainer,
  previewImage,
  image,
  settingsElement,
  additionalSettings,
  toggle,
} from "../style/pages/winter.module.scss";
import {
  detail,
  settingsContainer,
  titleSettings,
  priceInfo,
  mobCloseBtn,
  closeBtn,
  costBundle,
  detailHeader,
  settings,
  settingsBlock,
  productInfo,
  settingsList,
  orderInfo,
  productContainer,
  productItem,
  delProduct,
  mobileProducts,
  hint,
  nubmer,
} from "../components/product/product.module.scss";

import { StoreContext } from "../context/store-context";

function Winterscreens() {
  const imageData = useStaticQuery(graphql`
    {
      allFile(
        filter: {
          relativeDirectory: { eq: "screens/winter" }
          name: { nin: ["main"] }
        }
        sort: { order: ASC, fields: name }
      ) {
        nodes {
          childImageSharp {
            id
            gatsbyImageData(
              breakpoints: [750, 1366, 1920]
              formats: [AUTO, AVIF, WEBP]
              quality: 85
              placeholder: DOMINANT_COLOR
            )
          }
        }
      }
    }
  `);

  const [selectedImg, setSelectedImg] = useState(null);

  const { store, setStore } = useContext(StoreContext);
  const [totalPrice, setTotalPrice] = useState(0);

  const [quantity, setQuantity] = useState(0);

  const [totalAmount, setTotalAmount] = useState(0);

  const [mobileList, setMobileList] = useState(false);

  // Обновить состояние для количества
  const handleQuantity = (event) => {
    if (Number(event.target.value) >= 0) {
      setQuantity(Number(event.target.value));
    } else return;
  };

  // Добавить набор
  const addProduts = () => {
    const dataOrder = {
      quantity: quantity,
      totalAmount: totalAmount,
    };

    setTotalPrice(totalPrice + dataOrder.totalAmount);

    setStore({
      ...store,
      totalPrice: store.totalPrice + dataOrder.totalAmount,
      screens: {
        winter: {
          bundle: [...store.screens.winter.bundle, dataOrder],
          totalPrice: store.screens.winter.totalPrice + dataOrder.totalAmount,
        },
        amsterdam: { ...store.screens.amsterdam },
      },
      acrylicfalsebeads: { ...store.acrylicfalsebeads },
      frame: { ...store.frame },
      toppers: { ...store.toppers },
      stand: { ...store.stand },
      spatulas: { ...store.spatulas },
      tag: { ...store.tag },
      sticks: { ...store.sticks },
      substrates: { ...store.substrates },
    });

    setQuantity(0);
    setTotalAmount(0);
  };

  // Удалить набор подложек
  const deleteProduct = (index) => {
    // Массив всех наборов
    const arrBundle = [...store.screens.winter.bundle];
    // Цена набора, который нужно удалить
    const deductibleAmount = store.screens.winter.bundle[index].totalAmount;

    // Удаляем из массива наборов элемент с заданным index
    arrBundle.splice(index, 1);
    setStore({
      ...store,
      totalPrice: store.totalPrice - deductibleAmount,
      screens: {
        winter: {
          bundle: [...arrBundle],
          totalPrice: store.screens.winter.totalPrice - deductibleAmount,
        },
        amsterdam: { ...store.screens.amsterdam },
      },
      acrylicfalsebeads: { ...store.acrylicfalsebeads },
      frame: { ...store.frame },
      toppers: { ...store.toppers },
      stand: { ...store.stand },
      spatulas: { ...store.spatulas },
      tag: { ...store.tag },
      sticks: { ...store.sticks },
      substrates: { ...store.substrates },
    });
  };

  useEffect(() => {
    let q = quantity || 0;

    setTotalAmount(Math.ceil(q * 3000));
  }, [quantity]);

  return (
    <main className="mainpage">
      <MetaTag title="Декоративная ширма «Лесная сказка» | Wowmilota" />
      <motion.div layoutId="winterContainer" className={detail}>
        {selectedImg && (
          <ModalPhoto
            selectedImg={selectedImg}
            setSelectedImg={setSelectedImg}
          />
        )}

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.4 }}
          className={previewContainer}
        >
          {imageData?.allFile?.nodes.map((img, index) => {
            const { childImageSharp } = img;
            const src = getImage(img);

            return (
              <motion.div
                onClick={() => setSelectedImg(src)}
                onKeyDown={() => setSelectedImg(src)}
                className={previewImage}
                key={childImageSharp.id}
                whileHover={{ scale: 0.98 }}
              >
                <GatsbyImage
                  image={src}
                  alt="Фотография ширм амстердам"
                  className={image}
                />
              </motion.div>
            );
          })}
        </motion.div>

        <div className={settingsContainer}>
          <div className={settings}>
            <div className={settingsBlock}>
              <div className={productInfo}>
                <motion.div
                  className={detailHeader}
                  layout="position"
                  layoutId="winterTitle"
                >
                  <h2>Декоративная ширма «Лесная сказка»</h2>
                </motion.div>
                <p>
                  Стильное декоративное украшение. Создает уютную атмосферу
                  приближающегося праздника. А также станет, для ваших деток,
                  прекрасным проводником в мир сказок. С помощью нашей ширмы,
                  детки окунаются в мир фантазий, играя с фигурками, которые
                  входят в комплект.
                  <br />
                  Также, данный товар, вы с легкостью можете преподнести
                  друзьям, родителям, коллегам, деткам в качестве новогоднего
                  подарка.
                  <br />
                  Такой подарок действительно удивит и подарит самые теплые и
                  искренние эмоции.
                  <br />
                  Ширма компактно складывается, что позволяет ее удобно хранить
                  в течении года.
                  <br />
                  Также в комплект входит автономная гирлянда роса длиной 2м,
                  что позволит окончательно завершить весь декор
                </p>
              </div>

              <h3 className={titleSettings}>Обязательный параметр</h3>

              <div className={settingsList}>
                <div
                  className={settingsElement}
                  style={{
                    backgroundColor:
                      quantity > 0
                        ? "rgba(255, 255, 255, 0.15)"
                        : "rgba(255, 255, 255, 0.05)",
                  }}
                >
                  <h3>Количество</h3>
                  <div className={toggle}>
                    <TextField
                      id="winterQuantity"
                      variant="standard"
                      type="number"
                      onFocus={(e) => e.target.value === "0" && setQuantity("")}
                      onChange={handleQuantity}
                      value={quantity}
                      sx={textFieldSx}
                      inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                    />
                  </div>
                </div>
              </div>

              <h3 className={titleSettings}>Характеристики</h3>

              <div className={additionalSettings}>
                <div
                  className={settingsElement}
                  style={{ backgroundColor: "rgba(255, 255, 255, 0.15)" }}
                >
                  <h3>Толщина</h3>
                  <p>6мм</p>
                </div>
                <div
                  className={settingsElement}
                  style={{ backgroundColor: "rgba(255, 255, 255, 0.15)" }}
                >
                  <h3>Длина</h3>
                  <p>Около 1м</p>
                </div>
                <div
                  className={settingsElement}
                  style={{ backgroundColor: "rgba(255, 255, 255, 0.15)" }}
                >
                  <h3>Высота</h3>
                  <p>20 см</p>
                </div>
                <div
                  className={settingsElement}
                  style={{ backgroundColor: "rgba(255, 255, 255, 0.15)" }}
                >
                  <h3>Дополнительно</h3>
                  <p>
                    В комплект входят фигурки Оленя и зайчат, Гирлянда роса 2м,
                    батарейки, стильная упаковочная коробка.
                  </p>
                </div>
              </div>
            </div>

            <div className={orderInfo}>
              <h3>Ваши наборы</h3>
              {store?.screens.winter.totalPrice > 0 ? (
                <div className={productContainer}>
                  {store?.screens.winter.bundle.map((item, index) => {
                    return (
                      <div key={`order_${index}`} className={productItem}>
                        <button
                          className={delProduct}
                          aria-label="Удалить"
                          onClick={() => deleteProduct(index)}
                        >
                          <MdOutlineClose color="white" />
                        </button>
                        <h3>Набор {index + 1}</h3>
                        <p>{item.quantity} шт.</p>
                        <p>{item.totalAmount} руб.</p>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className={hint}>
                  <p>
                    Пока у Вас нет наборов.
                    <br />
                    <br />
                    Когда Вы выберите все обязательные параметры, кнопка внизу
                    покажет стоимость набора.
                    <br /> <br />
                    Вы можете формировать любое количество наборов, просто
                    продолжайте выбирать параметры.
                  </p>
                </div>
              )}
            </div>

            <div className={priceInfo}>
              <div className={mobileProducts}>
                <button onClick={() => setMobileList(!mobileList)}>
                  <MdShoppingBasket color="white" />
                  <p className={nubmer}>
                    {store?.screens.winter.bundle.length}
                  </p>
                </button>
                {mobileList && (
                  <Swiper
                    slidesPerView={"auto"}
                    spaceBetween={10}
                    grabCursor={true}
                    className={productContainer}
                  >
                    {store?.screens.winter.bundle.map((item, index) => {
                      return (
                        <SwiperSlide
                          key={`order_${index}`}
                          className={productItem}
                        >
                          <button
                            className="delProduct"
                            aria-label="Удалить"
                            onClick={() => deleteProduct(index)}
                          >
                            <MdOutlineClose color="white" />
                          </button>
                          <h3>Набор {index + 1}</h3>
                          <p>{item.quantity} шт.</p>
                          <p>{item.totalAmount} руб.</p>
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                )}
              </div>
              {totalAmount > 0 ? (
                <button className={costBundle} onClick={addProduts}>
                  {totalAmount > 0
                    ? `Добавить набор ${totalAmount} руб.`
                    : "Сформируйте набор"}
                </button>
              ) : (
                <p className={costBundle}>Сформируйте набор</p>
              )}
              <Link to="/" className={mobCloseBtn}>
                <MdOutlineClose color="white" />
              </Link>
              <Link to="/" className={closeBtn}>
                {store?.screens.winter.bundle.length > 0
                  ? `Сохранить и закрыть`
                  : `закрыть`}
              </Link>
            </div>
          </div>
        </div>
      </motion.div>
    </main>
  );
}

export default Winterscreens;
