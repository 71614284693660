import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { motion } from 'framer-motion'
import { backdrop, photo } from './modalPhoto.module.scss'

function ModalPhoto({ selectedImg, setSelectedImg }) {

    const handleClick = (e) => {
      setSelectedImg(null);
    }

    return (
      <motion.div className={backdrop}
        onClick={handleClick}
        onKeyDown={handleClick}
        role="button"
        tabIndex={0}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}>
        <motion.div
          initial={{ scale: 0.9, y: 15, opacity: 0 }}
          animate={{ scale: 1, y: 0, opacity: 1 }}
          transition={{ type: 'spring', bounce: 0.35 }}
          className={photo}
        >
          <GatsbyImage image={selectedImg} alt="Фото продукции wowmilota" objectFit='contain'/>
        </motion.div>
      </motion.div>
    )
  }

export default ModalPhoto
