// extracted by mini-css-extract-plugin
export var closeBtn = "product-module--closeBtn--97cf3";
export var costBundle = "product-module--costBundle--1849e";
export var delProduct = "product-module--delProduct--9768d";
export var detail = "product-module--detail--35657";
export var detailHeader = "product-module--detailHeader--c10d5";
export var hint = "product-module--hint--d3969";
export var mobCloseBtn = "product-module--mobCloseBtn--3d8d0";
export var mobileProducts = "product-module--mobileProducts--2895e";
export var nubmer = "product-module--nubmer--5c0d6";
export var orderInfo = "product-module--orderInfo--4fab8";
export var priceInfo = "product-module--priceInfo--23d3d";
export var productContainer = "product-module--productContainer--c48ae";
export var productInfo = "product-module--productInfo--f8235";
export var productItem = "product-module--productItem--1d4e5";
export var schemeBtn = "product-module--schemeBtn--c2491";
export var settings = "product-module--settings--9e1d1";
export var settingsBlock = "product-module--settingsBlock--6c043";
export var settingsContainer = "product-module--settingsContainer--59147";
export var settingsList = "product-module--settingsList--ee019";
export var titleSettings = "product-module--titleSettings--bdf93";